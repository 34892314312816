import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DashboardItem from './dashboardItem';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, LAPTOP, TABLET, media,
} from '../../constants/screens';

const Dashboard = ({ content }) => (
  <Container>
    <Content>
      {
          content.map(item => (
            <DashboardItem
              key={item.title}
              {...item}
            />
          ))
        }
    </Content>
  </Container>
);

Dashboard.propTypes = {
  content: PropTypes.instanceOf(Array).isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(128deg, #2a159a, #2a159a, #6b27e8);
  padding: 250px 5% 190px;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 140px 5% 75px;
  }
`;

const Content = styled.div`
  display: inline-flex;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
    
    > div:not(:last-child) {
      margin-bottom: 150px;
    }
  }
`;

export default Dashboard;
