import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import RoundedButton from '../buttons/roundedButton';
import { DARK_BLUE, TURQUOISE, WHITE } from '../../constants/colors';
import { LAPTOP, MOBILE } from '../../constants/screens';

const DashboardItem = ({
  title,
  lead,
  description: { md },
  icon: { file: { localFile: { childImageSharp: { sizes } } } },
  button: { url, text },
}) => (
  <Container>
    <IconWrapper>
      <Image sizes={sizes} />
    </IconWrapper>
    <TextContainer>
      <Title>
        {lead}
      </Title>
      <SubTitle>
        {title}
      </SubTitle>
      <Text source={md} />
    </TextContainer>
    <RoundedButton
      text={text}
      color={TURQUOISE}
      hoverTextColor={WHITE}
      href={url}
      tag="a"
    />
  </Container>
);

DashboardItem.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  description: PropTypes.shape(),
  button: PropTypes.shape(),
  icon: PropTypes.shape().isRequired,
};

DashboardItem.defaultProps = {
  title: '',
  lead: '',
  description: {},
  button: {},
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${DARK_BLUE};
  flex-basis: 30%;
  box-shadow: 0 0 39.1px 6.9px rgba(0, 0, 0, 0.31);
  padding: 140px 25px 70px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 500px;
    padding: 100px 20px 50px;
    
    > button {
      margin-top: 45px;
    }
  }
  
  @media screen and (max-width: 500px) {
    > button {
      width: 250px;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const Title = styled.p`
  font-family: AvenirNextLTProBold;
  font-size: 19px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${TURQUOISE};
  margin-bottom: 20px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 16px;
    margin-bottom: 15px;
    min-height: 38px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 17px;
  }
`;

const SubTitle = styled.h4`
  font-size: 33px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${WHITE};
  margin-bottom: 35px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 24px;
    margin-bottom: 25px;
    min-height: 102px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 24px;
  }
`;

const Text = styled(ReactMarkdown)`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${WHITE};
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 17px;
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 17px;
  }
`;

const IconWrapper = styled.div`
  width: 126px;
  position: absolute;
  top: -60px;
  
  > div {
    width: 100%;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    width: 90px;
    top: -45px;
  }
`;

export default DashboardItem;
