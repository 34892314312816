import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import ResearchItem from './researchItem';
import { BLACK, BLUE, WHITE } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Research = ({
  research, questions,
}) => (
  <Content>
    <Title>{research.title}</Title>
    <WhatWeResearch
      background={pathOr('', ['image', 'file', 'localFile', 'childImageSharp', 'original', 'src'], research)}
    >
      {
        research.content.map(item => (
          <ResearchItem
            key={item.id}
            blueText={item.title}
            grayText={item.lead}
          />
        ))
      }
    </WhatWeResearch>
    <QuestionsContainer>
      <ImageWrapper>
        <Image
          sizes={pathOr('', ['image', 'file', 'localFile', 'childImageSharp', 'sizes'], questions)}
          alt={pathOr('', ['image', 'alt'], questions)}
        />
      </ImageWrapper>
      <QuestionsContainerText>
        <QuestionsContainerTitle>
          {questions.title}
        </QuestionsContainerTitle>
        <TextWrapper>
          <Text>
            {questions.description}
          </Text>
        </TextWrapper>
      </QuestionsContainerText>
    </QuestionsContainer>
  </Content>
);

Research.propTypes = {
  research: PropTypes.shape(),
  questions: PropTypes.shape(),
};

Research.defaultProps = {
  research: { content: [] },
  questions: {},
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 170px 5% 220px;
  background-color: ${WHITE};
  box-shadow: 0 0 34px 6px rgba(220, 227, 238, 0.6);
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 130px 5% 160px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const Title = styled.h4`
  font-size: 33px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${BLUE};
  max-width: 550px;
  text-transform: uppercase;
  margin-bottom: 44px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 24px;
  }
`;

const WhatWeResearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: url(${props => props.background}) no-repeat;
  background-size: 100%;
  max-width: 1600px;
  width: 100%;
  padding: 45px 0 0 0;
  margin-bottom: 250px;
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 100px;
  }
  
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  width: 100%;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 900px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  flex: 0.4;
  box-sizing: border-box;
  
  @media screen and (max-width: ${TABLET}) {
    width: 100%;
    max-width: 500px;
    margin-bottom: 100px;
  }
  
  > div {
    width: 100%;
  }
`;

const QuestionsContainerText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  
  @media screen and (max-width: ${TABLET}) {
    > h4, p {
      text-align: center;
    }
  }
`;

const QuestionsContainerTitle = styled.h4`
  font-size: 33px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  max-width: 530px;
  color: ${BLUE};
  text-transform: uppercase;
  margin-bottom: 40px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 24px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 24px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  > p:not(:last-child) {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    max-width: 550px;
  }
`;

const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 26px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${BLACK};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 20px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 18px;
  }
`;

export default Research;
