import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BLUE, DARK_GRAY, WHITE } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const ResearchItem = ({ blueText, grayText }) => (
  <Content>
    <TextContainer>
      <Text blueText={blueText}>
        {grayText}
      </Text>
    </TextContainer>
  </Content>
);

ResearchItem.propTypes = {
  blueText: PropTypes.string,
  grayText: PropTypes.string,
};

ResearchItem.defaultProps = {
  blueText: '',
  grayText: '',
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 720px;
  width: 100%;
  padding: 25px 10px;
  border-radius: 10px;
  background-color: ${WHITE};
  box-shadow: 0 0 34px 6px rgba(220, 227, 238, 0.6);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 670px;
`;

const Text = styled.h4`
  font-size: 26px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${DARK_GRAY};
  
  &:before {
    color: ${BLUE};
    content: '${props => props.blueText} ';
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 20px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 18px;
  }
`;

export default ResearchItem;
