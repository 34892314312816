import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import Introduction from '../components/developerResearch/introduction';
import Research from '../components/developerResearch/research';
import ContactUs from '../components/common/contactUs';
import Dashboard from '../components/developerResearch/dashboard';
import withStickyHeader from '../enhancers/withStickyHeader';
import withCustomLayout from '../enhancers/withCustomLayout';
import { LIGHT_SCHEME } from '../constants/colors';

const DeveloperResearch = (props) => {
  const {
    data: {
      page: { introduction, sections },
    },
  } = props;

  const contactsSection = sections.filter(({ meta = '' }) => meta.includes('contact'));

  return (
    <div>
      <Introduction {...introduction} />
      <Research
        research={sections[0]}
        questions={sections[1]}
      />
      <Dashboard {...sections[2]} />
      {contactsSection && contactsSection[0] && (
        <ContactUs {...contactsSection[0]} />
      )}
    </div>
  );
};

DeveloperResearch.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape().isRequired,
  }).isRequired,
};

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: LIGHT_SCHEME }),
)(DeveloperResearch);

// eslint-disable-next-line
export const query = graphql`
  query DeveloperResearchPageQuery {
    page(url: { regex: "/research/" }) {
      introduction {
        title
        titleSecondary
        subTitle
        description
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 770) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        id: _id
        title
        description
        meta
        content {
          id: _id
          title
          lead
          description {
            md
          }
          button {
            url
            text
          }
          icon {
            file {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 126) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
          }
        }
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 400) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
