import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info from '../common/info';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, TABLET, media, LAPTOP,
} from '../../constants/screens';
import { TURQUOISE, WHITE } from '../../constants/colors';

const Introduction = ({
  image: { alt, file: { localFile: { childImageSharp: { sizes } } } },
  title,
  titleSecondary,
  subTitle,
  description,
}) => (
  <Container>
    <Content>
      <AboutUsItem
        image={sizes}
        imgAltAttr={alt}
        maxImageWidth="770px"
      >
        <Info
          title={subTitle}
          additionalSubTitle={title}
          subTitle={titleSecondary}
          text={description}
          titleColor={TURQUOISE}
          subTitleColor={WHITE}
          textColor={WHITE}
          subTitleFontSize="40px"
          pageName="developer-research"
        />
      </AboutUsItem>
    </Content>
  </Container>
);

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  titleSecondary: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(126deg, #2a159a, #2a159a 46%, #2b55c5);
  padding: 300px 5% 150px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 220px 5% 120px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 150px 5%;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: 1600px) {
    padding: 225px 5% 75px 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
  }
  
  > div {
    width: 100%;
  }
`;

export default Introduction;
